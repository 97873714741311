<template>
    <v-card width="350" class="rounded" v-if="fixedPercentage">
        <div class="d-flex align-center">
            <v-card-subtitle>
                <v-icon>{{cIcon}}</v-icon>
            </v-card-subtitle>
            <v-card-subtitle>
                {{pText}}{{pValue}} <span class="caption">時間</span>
            </v-card-subtitle>
            <v-spacer />
            <v-card-subtitle class="py-1">
                <v-icon large :color="defaultColor">{{defaultIcon}}</v-icon>
                <span>{{fixedPercentage.toFixed(2)}}%</span>
            </v-card-subtitle>
        </div>
        <div class="d-flex align-center">
            <div>
                <v-card-text class="text-h3 py-0">
                    {{cValue}}
                    <span class="caption">時間</span>
                </v-card-text>
                <v-card-subtitle class="pt-0">{{cText}}</v-card-subtitle>
            </div>
            <v-spacer />
            <div class="pr-3">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" v-bind="attrs" v-on="on">
                            mdi-progress-question
                        </v-icon>
                    </template>
                    <div>{{cToolTips}}</div>
                </v-tooltip>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props:[
        "cIcon",
        "cPercentage",
        "cText",
        "pText",
        "cToolTips",
        "cValue",
        "pValue",
        "reverseColor"
    ],
    data:()=>({
        fixedPercentage :null,
        defaultColor:"success",
        defaultIcon:"mdi-menu-up" //mdi-menu-down
    }),
    mounted(){
        this.fixedPercentage = this.cPercentage
        if(this.cPercentage < 0){
            this.fixedPercentage *= -1
            this.defaultIcon = "mdi-menu-down"
            this.defaultColor = "error"
        }
        if(this.reverseColor){
            if(this.defaultColor === "error")
                this.defaultColor = "success"
            else this.defaultColor = "error"
        }
            
    }
}
</script>