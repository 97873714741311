<template>
    <div>
        <span class="caption">アナリティクス : 最終更新日{{lastUpdated}}</span>
        <div v-if="loaded" style="display:grid; gap:.5em;">
            <total-work-time-card 
                :cIcon="'mdi-clock-check'"
                :cPercentage="diffInPercentage(analyResult.totalWorkTime.c,analyResult.totalWorkTime.p)"
                :cText="'総労働時間'"
                :pText="'先月'"
                :cToolTips="
                    '今月と先月の総労働時間の比較 (先月から今日までの比較になります)'
                "
                :cValue="$gf.convertTime(analyResult.totalWorkTime.c) "
                :pValue="$gf.convertTime(analyResult.totalWorkTime.p) "
            />
            <total-work-time-card 
                :cIcon="'mdi-clock-alert'"
                :cPercentage="diffInPercentage(analyResult.totalOtTime.c,analyResult.totalOtTime.p)"
                :cText="'総残業時間'"
                :pText="'先月'"
                :cToolTips="
                    '今月と先月の総残業時間(労働時間 - 休憩時間)の比較 (先月から今日までの比較になります)'
                "
                :cValue="$gf.convertTime(analyResult.totalOtTime.c) "
                :pValue="$gf.convertTime(analyResult.totalOtTime.p) "
                :reverseColor=true
            />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import TotalWorkTimeCard from '@/components/DashboardComponents/Home/TotalWorkTimeCard.vue'
export default {
    data:()=>({
        recordData:null,
        otThreshHold:null,
        analyResult:{
            totalWorkTime:{
                c:null,
                p:null
            },
            totalOtTime:{
                c:null,
                p:null
            },
            avgWorkTime:{
                c:null,
                p:null
            },
            avgOtTime:{
                c:null,
                p:null
            }
        },
        lastUpdated:null,
        loaded:false
    }),
    async mounted(){
        this.lastUpdated = dayjs(localStorage.getItem("lUpt")).format("YYYY-MM-DD")
        if(this.lastUpdated && this.hasValidLocalStorageItem())
            if(dayjs().isAfter(this.lastUpdated, 'day')){
                await this.init()
            }else{
                this.otThreshHold = Number(localStorage.getItem("otT"))
                this.analyResult = JSON.parse(localStorage.getItem("rs"))
            }
        else {
           await this.init()
        }
        this.loaded = true
    },
    methods:{
        async init(){
            await this.startAnalyze()
            localStorage.setItem("rs", JSON.stringify(this.analyResult))
            localStorage.setItem("otT", JSON.stringify(this.otThreshHold.value))
            localStorage.setItem("lUpt", dayjs().format('YYYY-MM-DD'))
        },
        hasValidLocalStorageItem(){
            let result = JSON.parse(localStorage.getItem("rs"))
            let otThreshHold = Number(localStorage.getItem("otT"))
            if(isNaN(otThreshHold)) return false
            if(result){
                if(!result.totalWorkTime) return false
                if(!result.totalOtTime) return false
                if(isNaN(result.totalWorkTime.c)) return false
                if(isNaN(result.totalWorkTime.p)) return false
                if(isNaN(result.totalOtTime.c)) return false
                if(isNaN(result.totalOtTime.p)) return false
                //if(isNaN(result.avgWorkTime.c)) return false
                //if(isNaN(result.avgWorkTime.p)) return false
                //if(isNaN(result.avgOtTime.c)) return false
                //if(isNaN(result.avgOtTime.p)) return false
            }else return false
            return true
        },
        async startAnalyze(){
            let currentQuery = {
                from:dayjs().startOf('month').format('YYYY-MM-DD'),
                to:dayjs().format('YYYY-MM-DD')
            }
            let previousQuery = {
                from:dayjs().add(-1,'month').startOf('month').format('YYYY-MM-DD'),
                to:dayjs().add(-1,'month').endOf('month').format('YYYY-MM-DD')
            }
            this.recordData = await this.getTargetedRecordData(currentQuery, previousQuery)
            this.otThreshHold = await this.getOtSettings()
            let resultC = this.analyzeRecord(this.recordData.current, this.otThreshHold.value)
            let resultP = this.analyzeRecord(this.recordData.previous, this.otThreshHold.value)
            this.analyResult.totalWorkTime.c = resultC.workTime
            this.analyResult.totalOtTime.c = resultC.otTime
            this.analyResult.totalWorkTime.p = resultP.workTime
            this.analyResult.totalOtTime.p = resultP.otTime
            //let avgC = this.analyzeRecord(resultC, recordData)
        },
        diffInPercentage(c,p){
            let convertedC = this.$gf.convertTime(c)
            let convertedP = this.$gf.convertTime(p)
            return (convertedC-convertedP)/convertedP * 100
        },
        analyzeRecord(data, otRate){
            let workTime = 0
            let otTime = 0
            let otRateInMs = otRate * 60 * 60 * 1000
            data.forEach(user => {
                user.records.forEach(record => {
                    let restTime = 0
                    workTime += record.totalTime
                    //Calcualte RestTime
                    if(record.subRecords.length > 0)
                        record.subRecords.forEach(subRecord => {
                            if(
                                subRecord.subRecordType[0] === true &&
                                subRecord.subRecordType[1] === true &&
                                subRecord.subRecordType[2] === false
                            )
                            restTime += subRecord.subTotalTime
                        });
                    let calculateOT = record.totalTime-restTime-otRateInMs
                    if(calculateOT > 0)
                        otTime += calculateOT
                })
            });
            return {workTime, otTime}
        },
        async getTargetedRecordData(currentQ, previousQ){
            let responseCurrent = await this.$store.dispatch("get", {
                url: `/api/getAllUserRecord?f=${currentQ.from}&t=${currentQ.to}`,
            });
            if (!responseCurrent && !responseCurrent.result) return
            let responsePrevious = await this.$store.dispatch("get", {
                url: `/api/getAllUserRecord?f=${previousQ.from}&t=${previousQ.to}`,
            });
            if (!responsePrevious && !responsePrevious.result) return
            return {current:responseCurrent.result, previous:responsePrevious.result}
        },
        async getOtSettings(){
            let response = await this.$store.dispatch("get", {
                url: `/api/getSettings`,
            });
            if (response && response.result) return response.result.find(set =>set.codeName === "HIZANGYOUJIKAN")
        },
    },
    components:{
        TotalWorkTimeCard
    }

}
</script>

<style>

</style>