<template>
    <div class="mainConGrid">
        <user-count-panel />
        <div class="midGridCon">
            <user-analytics-panel />
            <div style="display:grid; place-items:center">
                <div style="width:200px;">
                    <clock />
                    <div class="cusFont">{{dateNow}}</div>
                    <div class="cusFont">{{dayNow}}</div>
                </div>
            </div>
            <user-accessibility-panel class="ml-6"/>
        </div>
        <div class="d-flex">
            <genba-n-koushu-count-panel />
            <shinsei-count-card style="margin-left:.5em;"/>
           
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import Clock from '@/components/generalComponents/Clock.vue'
import UserAnalyticsPanel from './userAnalyticsPanel.vue'
import UserCountPanel from './userCountPanel.vue'
import GenbaNKoushuCountPanel from './GenbaNKoushuCountPanel.vue'
import UserAccessibilityPanel from './userAccessibilityPanel.vue'
import ShinseiCountCard from '@/components/DashboardComponents/Home/ShinseiCountCard.vue'
export default {

    components:{
        UserCountPanel,
        UserAccessibilityPanel,
        UserAnalyticsPanel,
        Clock,
        ShinseiCountCard,
        GenbaNKoushuCountPanel
    },
    computed:{
        dateNow(){
            let date = dayjs().format('YYYY-MM-DD').split("-")
            return `${date[0]}年${date[1]}月${date[2]}日`
        },
        dayNow(){
            return this.$gf.convertToDay(dayjs().day())
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
.cusFont{
    font-family: 'Zen Dots', cursive;
}
.mainConGrid{
    display:grid;
    place-items: center;
    gap:.5em;
}
.midGridCon{
    display:grid;
    grid-auto-flow: column;
    gap:1em;
}
</style>