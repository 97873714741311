<template>
    <v-simple-table v-if="accessData" height="300">
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">日付</th>
                    <th class="text-left">名前</th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in accessData" :key="user._id">
                    <td>{{ formatDate(user.date) }}</td>
                    <td>{{ user.uid.name }}</td>
                    <td>
                        <v-chip color=primary v-if="user.uid.accType === 38560923">
                            管理者
                        </v-chip>
                        <v-chip color=primary v-else>
                            ユーザー
                        </v-chip>
                    </td>
                    <td>{{ fromNow(user.date) }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
export default {
    data: () => ({
        accessData: null,
    }),
    async mounted() {
        this.accessData = await this.getUserAccessData();
        dayjs.updateLocale('en', {
            relativeTime: {
                past: "%s前",
                s: '数秒前',
                m: "１分",
                mm: "%d 分",
                h: "1時間",
                hh: "%d 時間",　
                d: "一日",
                dd: "%d 日",
                M: "1ヶ月",
                MM: "%d ヶ月",
                y: "一年",
                yy: "%d 年"
            }
        })
    },
    methods: {
        fromNow(date){
            return dayjs(date).fromNow() 
        },
        formatDate(date){
            return dayjs(date).format('YYYY-MM-DD HH:mm')
        },
        async getUserAccessData() {
            let response = await this.$store.dispatch("get", {
                url: "/api/getUserAccessData",
            });
            if (response && response.result) return response.result;
        },
    },
};
</script>
