<template>
    <div>
        <div class="clock">
            {{time}}
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            time:null
        }
    },

    mounted(){
        setInterval(()=>{
            this.updateTime()
        },1000)
    },
    methods:{
        updateTime(){
            function zeroPadding(num, digit) {
                var zero = '';
                for(var i = 0; i < digit; i++) {
                    zero += '0';
                }
                return (zero + num).slice(-digit);
            }
            var cd = new Date();
            this.time = zeroPadding(cd.getHours(), 2) + ':' + zeroPadding(cd.getMinutes(), 2) + ':' + zeroPadding(cd.getSeconds(), 2);
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
.clock{
    font-size: 200%;
    font-family: 'Zen Dots', cursive;
}
</style>


