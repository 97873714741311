<template>
    <div style="width:500px;">
        <div class="caption">ログイン情報 : 直近５件</div>
        <user-accessibility-table />
    </div>
</template>

<script>
import UserAccessibilityTable from '@/components/DashboardComponents/Home/UserAccessibilityTable.vue'
export default {
    components:{
        UserAccessibilityTable
    },
    data:()=>({
    })
}
</script>

<style scoped>

</style>