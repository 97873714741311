<template>
    <v-card width="265" class="d-flex justify-center rounded">
        <div>
            <v-card-title class="pr-1">
                <v-icon size=100>mdi-form-select</v-icon>
            </v-card-title>
        </div>
        <div>
            <v-card-subtitle>処理待ち申請</v-card-subtitle>
            <div class="text-h4 pb-3 pl-3">{{shinseiCount}}件</div>
        </div>
    </v-card>
</template>

<script>
export default {
    data:()=>({
        shinseiCount:0
    }),
    async mounted(){
        this.shinseiCount =( await this.getShinseiCount()) || 0
    },
    methods:{
        async getShinseiCount(){
            let response = await this.$store.dispatch("get", {
                url: `/api/getShinseiCount`,
            });
            if (response && response.result) return response.result
        },
    }
}
</script>