<template>
    <v-card width="271" class="d-flex justify-center rounded">
        <div>
            <v-card-subtitle>{{textLabel}}</v-card-subtitle>
            <div class="text-h3 pa-1">{{textContent}}</div>
        </div>
        <div>
            <v-card-title>
                <v-icon size=100>
                    {{textIcon}}
                </v-icon>
            </v-card-title>
        </div>
    </v-card>
</template>

<script>
export default {
    props:[
        'textLabel',
        'textContent',
        'textIcon',
    ],
    data:()=>({
    })
}
</script>