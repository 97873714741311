<template>
    <div v-if="userCountData">
        <div class="caption">ユーザー情報</div>
        <div class="userPanel">
            <user-count-card :textLabel="'登録者数'" :textContent="userCountData.totalRegistered" :textIcon="'mdi-account-multiple'"/>
            <user-count-card :textLabel="'管理者'" :textContent="userCountData.totalAdmin" :textIcon="'mdi-account-cog'"/>
            <user-count-card :textLabel="'ユーザー'" :textContent="userCountData.totalUsers" :textIcon="'mdi-account'"/>
            <user-count-card :textLabel="'削除済み'" :textContent="userCountData.totalDeleted" :textIcon="'mdi-account-cancel'"/>
        </div>
    </div>
</template>

<script>
import UserCountCard from '@/components/DashboardComponents/Home/UserCountCard.vue'
export default {
    components:{
        UserCountCard
    },
    data:()=>({
        userCountData:null
    }),
    async mounted(){
        this.userCountData = await this.getUserCountInfo()
    },
    methods:{
        async getUserCountInfo(){
            let response = await this.$store.dispatch("get", {
                url: `/api/getUserCountInfo`,
            });
            if (response && response.result) return response.result
        },
    }
    
}
</script>

<style scoped>
.userPanel{
    display:grid;
    grid-auto-flow: column;
    gap :.5em;
}
</style>