<template>
    <v-card class="pa-3 d-flex justify-space-between" >
        <div class="centerGrid" style="position:relative;">
            <span class="caption"> {{leftText}} </span>
            <div style="position:absolute; left:50%; top:20%;">{{leftValue}}</div>
        </div>
        <div>
            <v-progress-circular
                :rotate="90"
                :size="120"
                :width="15"
                :value="percentageValue"
                color="primary"
            >
            <slot>
            </slot>
            </v-progress-circular>
        </div>
        <div class="centerGrid" style="position:relative;">
            <span class="caption"> {{rightText}} </span>
            <div style="position:absolute; right:50%; top:20%;">{{rightValue}}</div>
        </div>
    </v-card>
</template>

<script>
export default {
    props:[
        'leftText',
        'rightText',
        'leftValue',
        'rightValue',
        
    ],
    computed:{
        percentageValue(){
            return this.leftValue/(this.leftValue+this.rightValue)*100
        }
    }
}
</script>

<style scoped>
.centerGrid{
    display:grid;
    justify-content: center;
    justify-items:center;
    align-content: flex-start;
}
</style>
